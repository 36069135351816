import React from 'react';
import I18n from '../../i18n';
import QuestionRow from './QuestionRow';
import DisplayTable from './DisplayTable';

const DisplayQuestionTable = ({
  questions,
  editingQuestionId,
  editingData,
  editingDataMap,
  setEditingDataMap,
  duplicatedRows = [],
  newQuestion,
  newRowRef,
  selectedQuestionId,
  handleQuestionEdit,
  handleQuestionDelete,
  handleQuestionSave,
  handleQuestionDuplicate,
  handleQuestionCancel,
  setEditingData,
  setNewQuestion,
  handleQuestionRowClick,
}) => {
  const headers = [
    { 
      title: I18n.t('admin.tableHeaders.actions'), 
      className: 'col-actions',
      tooltip: I18n.t('admin.tableHeaders.tooltips.actions')
    },
    { 
      title: I18n.t('admin.tableHeaders.questionId'), 
      className: 'col-id',
      tooltip: I18n.t('admin.tableHeaders.tooltips.questionId')
    },
    { 
      title: I18n.t('admin.tableHeaders.name'), 
      className: 'col-name',
      tooltip: I18n.t('admin.tableHeaders.tooltips.name')
    },
    { 
      title: I18n.t('admin.tableHeaders.text'), 
      className: 'col-text',
      tooltip: I18n.t('admin.tableHeaders.tooltips.text')
    },
    { 
      title: I18n.t('admin.tableHeaders.version'), 
      className: 'col-version',
      tooltip: I18n.t('admin.tableHeaders.tooltips.version')
    },
    { 
      title: I18n.t('admin.tableHeaders.difficulty'), 
      className: 'col-difficulty',
      tooltip: I18n.t('admin.tableHeaders.tooltips.difficulty')
    },
    { 
      title: I18n.t('admin.tableHeaders.timeComplexity'), 
      className: 'col-time-complexity',
      tooltip: I18n.t('admin.tableHeaders.tooltips.timeComplexity')
    },
    { 
      title: I18n.t('admin.tableHeaders.recursive'), 
      className: 'col-checkbox',
      tooltip: I18n.t('admin.tableHeaders.tooltips.recursive')
    },
    { 
      title: I18n.t('admin.tableHeaders.maxLoops'), 
      className: 'col-max-loops',
      tooltip: I18n.t('admin.tableHeaders.tooltips.maxLoops')
    }
  ];

  const renderRow = (question) => {
    if (!question) return null;
    
    const isDuplicate = question.id?.toString().startsWith('dup_');
    const isEditing = editingQuestionId === question.id || duplicatedRows.includes(question.id);
    const currentEditData = isDuplicate ? editingDataMap[question.id] : editingData;

    return (
      <QuestionRow
        question={question}
        isEditing={isEditing || question === newQuestion}
        editData={question === newQuestion ? question : currentEditData}
        onEdit={handleQuestionEdit}
        onDuplicate={handleQuestionDuplicate}
        onDelete={handleQuestionDelete}
        onSave={handleQuestionSave}
        onCancel={handleQuestionCancel}
        onChange={isDuplicate 
          ? (data) => setEditingDataMap(prev => ({...prev, [question.id]: data}))
          : setEditingData
        }
      />
    );
  };

  const renderNewRow = () => {
    if (!newQuestion) return null;
    
    return (
      <QuestionRow
        question={newQuestion}
        isEditing={true}
        editData={newQuestion}
        onEdit={handleQuestionEdit}
        onDuplicate={handleQuestionDuplicate}
        onDelete={handleQuestionDelete}
        onSave={handleQuestionSave}
        onCancel={() => {
          setNewQuestion(null);
          setEditingData(null);
        }}
        onChange={(data) => {
          setNewQuestion(data);
          setEditingData(data);
        }}
      />
    );
  };

  return (
    <DisplayTable
      headers={headers}
      data={questions.map(question => ({
        ...question,
        className: `question-row ${!question.id.toString().startsWith('dup_') && selectedQuestionId === question.id ? 'selected' : ''}`,
        onClick: !question.id.toString().startsWith('dup_') ? () => handleQuestionRowClick(question.id) : undefined
      }))}
      editingId={editingQuestionId}
      editingDataMap={editingDataMap}
      duplicatedRows={duplicatedRows}
      newItem={newQuestion}
      newRowRef={newRowRef}
      renderEditRow={renderRow}
      renderDisplayRow={renderRow}
      renderNewRow={renderNewRow}
    />
  );
};

export default DisplayQuestionTable; 