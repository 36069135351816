export default {
  basic: {
    loading: 'טוען...',
    selectLanguage: 'בחר שפה',
  },
  navbar: {
    home: 'דף הבית',
    members: 'אזור אישי',
    account: 'החשבון שלי',
    practice: 'מערכת תרגול',
    performance: 'ביצועים',
    signin: 'התחברות',
    signup: 'הרשמה',
    signout: 'התנתקות',
    about: 'אודות',
    contact: 'צור קשר',
    admin: 'ניהול',
  },
  home: {
    introTitle: 'שלום וברוכים הבאים ל- testapp!',
    introSubTitle: 'מערכת תרגול ייחודית המאפשרת לך',
    introSubTitle2: 'הכנה מנצחת לבחינה!',
    intro1: 'שלום',
    intro2: 'שמי אליאור שקד',
    intro3: 'אני מזמין אותך לתרגל את יכולותיך בכתיבת קוד באופן',
    intro4: 'יצירתי ועצמאי',
    intro5: 'המערכת שלנו מציעה משוב על טסטים מגוונים לשאלות נפוצות במבחן ומאפשרות לך לאמוד את טיב הקוד שלך תוך עמידה בתנאי השאלות (רקורסיה, זמני ריצה ועוד…)הדרך שלנו ארוכה אבל אנו נעשה אותה ביחד תוך מטרה ברורה - לגרום לך לתכנת בצורה מיטבית',
    intro6: 'ולהצליח בבחינה',
    videoError: 'שגיאה בטעינת הסרטון',
  },
  account: {
    title: 'דף חשבון',
    name: 'שם:',
    email: 'אימייל:',
  },
  contact: {
    questions: 'לשאלות:',
    email: 'אימייל:',
  },
  about: {
    founder1: 'אליאור: ילד טוב ירושלים',
    founder2: 'עמית: ילד רע רחובות',
  },
  admin: {
    title: 'דף ניהול - דף סודי!',
    upload: 'העלה קובץ',
    default: 'לא נבחר קובץ',
    verify: 'אמת והעלה CSV',
    alertNoFile: 'לא נבחר קובץ',
    alertSuccess: 'הקובץ עובד בהצלחה',
    alertPleaseUpload: 'אנא העלה קובץ עם סיומת .csv',
    questions: "Questions",
    tests: "Tests",
    addQuestion: "Add New Question",
    addTest: "Add New Test", 
    confirmDeleteQuestion: "Are you sure you want to delete this question? This will also delete all associated tests.",
    confirmDeleteTest: "Are you sure you want to delete this test?",
    
    // Table headers
    tableHeaders: {
      name: "Name",
      text: "Question Text",
      version: "Version",
      difficulty: "Difficulty",
      timeComplexity: "Time Complexity",
      recursive: "Recursive",
      maxLoops: "Max Loops",
      actions: "Actions",
      questionName: "Question Name",
      testName: "Test Name",
      input: "Input",
      expected: "Expected Output",
      allowEachExpected: "Allow Each Expected",
      testId: "ID",
      questionId: "ID",
      tooltips: {
        questionId: "מזהה שנוצר אוטומטית",
        name: "שם השאלה - חייב להיות ייחודי ותיאורי\nדוגמה: \"מעבר על עץ בינארי\"",
        text: "תיאור השאלה והדרישות\nדוגמה: \"ממש פונקציה שעוברת על עץ בינארי בסדר תוך-סדרי\"",
        version: "פורמט גרסה: מספר, אותיות, או מספר ואחריו אותיות\nדוגמאות: \"1\", \"alpha\", \"1beta\", \"2a\"",
        difficulty: "מספר",
        timeComplexity: "סיבוכיות זמן צפויה של הפתרון\nאפשרויות: '', O(1), O(logn), O(n), O(nlogn), O(n^2), O(n^3), O(n^4), O(2^n)",
        recursive: "מציין אם הפתרון דורש רקורסיה\nכן/לא",
        maxLoops: "מספר מקסימלי של לולאות מותרות בפתרון\n-1 משמעותו ללא הגבלה\nדוגמה: 2 משמעותו מקסימום שתי לולאות מקוננות",
        actions: "פעולות זמינות: עריכה, שכפול, מחיקה",
        testId: "מזהה שנוצר אוטומטית",
        questionName: "השאלה אליה שייך המבחן\nבחר מהשאלות הקיימות",
        testName: "שם תיאורי למקרה הבדיקה\nדוגמה: \"בדיקת מערך ריק\", \"מקרה קצה - מספרים שליליים\"",
        input: "ערך קלט בפורמט מילון Python\nדוגמאות:\n{\"array\": [1, 2, 3]}\n{\"str\": \"hello\"}\n{} עבור קלט ריק",
        expected: "ערך פלט צפוי\nפורמטים תקפים:\n- מספרים: 42, -1.5\n- מחרוזות: \"hello\"\n- בוליאני: True, False\n- None\n- אוספים: [1, 2, 3], {\"key\": \"value\"}, (1, 2)",
        allowEachExpected: "אם מופעל, הפלט הצפוי צריך להיות רשימה, וכל אחד מהערכים יתקבל"
      }
    },

    // Button labels
    buttons: {
      edit: "Edit",
      delete: "Delete",
      save: "Save",
      cancel: "Cancel",
      duplicate: "Duplicate"
    },

    // Messages
    messages: {
      saveSuccess: "Successfully saved changes",
      saveError: "Error saving changes",
      deleteSuccess: "Successfully deleted",
      deleteError: "Error deleting item",
      invalidPythonDict: "Input must be a valid Python dictionary format (e.g. {'key': 'value'} or {}). Keys and values must be strings with quotes.",
      duplicateQuestionVersion: "שאלה עם שם וגרסה זהים כבר קיימת. אנא בחר שם או גרסה אחרים.",
      duplicateTestName: "טסט עם שם זהה כבר קיים עבור שאלה זו. אנא בחר שם טסט אחר.",
      duplicateWithTests: "האם ברצונך לשכפל גם את הטסטים המשויכים?",
      invalidVersion: "הגרסה חייבת להיות מספר, אותיות, או מספר ואחריו אותיות (לדוגמה: '1', 'alpha', '1beta')",
      invalidExpectedOutput: "פלט צפוי חייב להיות מספר, True, False, None, אוסף, או מחרוזת עם מרכאות"
    }
  },
  practice: {
    title: 'קוד פייתון לבדיקה',
    darkmode: 'מצב כהה',
    lightmode: 'מצב בהיר',
    font: 'גודל גופן',
    run: 'הרצה',
    submit: 'הגשה',
    output: 'פלט:',
    questionNotFound: 'השאלה {function_name} לא נמצאה.',
    didYouMean: 'האם התכוונת ל{match}?',
  },
  results: {
    functionName: 'שם הפונקציה',
    timeComplexity: 'סיבוכיות זמן ריצה',
    test: 'מבחן',
    error: 'שגיאה',
    pass: 'עבר',
    fail: 'נכשל',
    noTest: 'אין מבחן',
    noLimit: 'ללא מגבלה',
    input: 'Input:',
    expected: 'Expected Output:',
    actual: 'Actual Output:',
    failedRecursion: 'Code should be recursive',
    failedLoops: 'Question allowed for {n_loops_allowed} loops, but {actual_loops} found',
    failedStructure: 'Bad structure',
  },
  performance: {
    title: 'טבלת הגשות מעודכנת',
  },
  sign: {
    in: 'התחברות',
    up: 'הרשמה',
    email: 'אימייל',
    name: 'שם',
    back: 'חזרה',
    password: 'סיסמה',
    forgotPassword: 'שכחת סיסמה?',
    resetPassword: 'איפוס סיסמה',
    codeWill: 'קוד איפוס יישלח לאימייל שלך:',
    sendCode: 'שלח קוד איפוס',
    defaultCode: 'נא להכניס קוד אישור',
    defaultPassword: 'נא להכניס סיסמה חדשה',
    confirmAccount: 'נא לאשר חשבון',
    confirmationCode: 'קוד אישור',
    confirmAccountNow: 'אישור חשבון',
    resendCode: 'שלח קוד מחדש',
    changeSuccess: 'הסיסמה אופסה בהצלחה!',
  }
};
