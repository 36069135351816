export default {
  basic: {
    loading: 'جارٍ التحميل...',
    selectLanguage: 'اختر اللغة',
  },
  navbar: {
    home: 'الصفحة الرئيسية',
    members: 'منطقة الأعضاء',
    account: 'الحساب',
    practice: 'نظام التدريب',
    performance: 'الأداء',
    signin: 'تسجيل الدخول',
    signup: 'إنشاء حساب',
    signout: 'تسجيل الخروج',
    about: 'معلومات عنا',
    contact: 'اتصل بنا',
    admin: 'المشرف',
  },
  home: {
    introTitle: 'مرحباً بكم في testapp!',
    introSubTitle: 'نظام تدريب فريد يتيح لك التحضير للامتحان مع',
    introSubTitle2: 'نجاح مضمون!',
    intro1: 'مرحباً',
    intro2: 'اسمي إليور شاكيد',
    intro3: 'أدعوكم لممارسة مهاراتكم في البرمجة بطريقة',
    intro4: 'إبداعية ومستقلة',
    intro5: 'نظامنا يوفر لك ملاحظات حول اختبارات متعددة لأسئلة شائعة في الامتحان، مما يتيح لك تقييم جودة الكود الخاص بك مع الالتزام بشروط السؤال (التكرار، وقت التشغيل، وغيرها...). رحلتنا طويلة، ولكننا سنسيرها معاً بهدف واضح - مساعدتك على كتابة الكود بشكل مثالي',
    intro6: 'والنجاح في الامتحان',
    videoError: 'خطأ في تحميل الفيديو',
  },
  account: {
    title: 'صفحة الحساب',
    name: 'الاسم:',
    email: 'البريد الإلكتروني:',
  },
  contact: {
    questions: 'لأي استفسارات:',
    email: 'البريد الإلكتروني:',
  },
  about: {
    founder1: 'إليور: ولد جيد',
    founder2: 'عميت: ولد سيء',
  },
  admin: {
    title: 'صفحة المشرف - صفحة سرية!',
    upload: 'رفع ملف',
    default: 'لم يتم اختيار ملف',
    verify: 'تحقق وارفع ملف CSV',
    alertNoFile: 'لم يتم اختيار ملف',
    alertSuccess: 'تمت معالجة الملف بنجاح',
    alertPleaseUpload: 'يرجى رفع ملف بامتداد .csv',
    questions: "Questions",
    tests: "Tests",
    addQuestion: "Add New Question",
    addTest: "Add New Test", 
    confirmDeleteQuestion: "Are you sure you want to delete this question? This will also delete all associated tests.",
    confirmDeleteTest: "Are you sure you want to delete this test?",
    
    // Table headers
    tableHeaders: {
      name: "Name",
      text: "Question Text",
      version: "Version",
      difficulty: "Difficulty",
      timeComplexity: "Time Complexity",
      recursive: "Recursive",
      maxLoops: "Max Loops",
      actions: "Actions",
      questionName: "Question Name",
      testName: "Test Name",
      input: "Input",
      expected: "Expected Output",
      allowEachExpected: "Allow Each Expected",
      testId: "ID",
      questionId: "ID",
      tooltips: {
        questionId: "معرف تم إنشاؤه تلقائياً",
        name: "اسم السؤال - يجب أن يكون فريداً ووصفياً\nمثال: \"اجتياز الشجرة الثنائية\"",
        text: "وصف السؤال والمتطلبات\nمثال: \"قم بتنفيذ دالة تجتاز شجرة ثنائية بترتيب داخلي\"",
        version: "تنسيق الإصدار: رقم، أحرف، أو رقم متبوع بأحرف\nأمثلة: \"1\"، \"alpha\"، \"1beta\"، \"2a\"",
        difficulty: "رقم",
        timeComplexity: "تعقيد الوقت المتوقع للحل\nالخيارات: '', O(1)، O(logn)، O(n)، O(nlogn)، O(n^2)، O(n^3)، O(n^4)، O(2^n)",
        recursive: "يشير إلى ما إذا كان الحل يتطلب التكرار\nنعم/لا",
        maxLoops: "الحد الأقصى لعدد الحلقات المسموح بها في الحل\n-1 يعني بدون حد\nمثال: 2 يعني حد أقصى حلقتين متداخلتين",
        actions: "الإجراءات المتاحة: تعديل، نسخ، حذف",
        testId: "معرف تم إنشاؤه تلقائياً",
        questionName: "السؤال الذي ينتمي إليه الاختبار\nاختر من الأسئلة الموجودة",
        testName: "اسم وصفي لحالة الاختبار\nمثال: \"اختبار المصفوفة الفارغة\"، \"حالة حدية - أرقام سالبة\"",
        input: "قيمة الإدخال بتنسيق قاموس Python\nأمثلة:\n{\"array\": [1, 2, 3]}\n{\"str\": \"hello\"}\n{} للإدخال الفارغ",
        expected: "قيمة الإخراج المتوقعة\nالتنسيقات الصالحة:\n- أرقام: 42، -1.5\n- نصوص: \"hello\"\n- قيم منطقية: True، False\n- None\n- مجموعات: [1, 2, 3]، {\"key\": \"value\"}، (1, 2)",
        allowEachExpected: "إذا تم تفعيله، يجب أن تكون النتيجة المتوقعة قائمة، وسيتم قبول كل واحدة من القيم"
      }
    },

    // Button labels
    buttons: {
      edit: "Edit",
      delete: "Delete",
      save: "Save",
      cancel: "Cancel",
      duplicate: "Duplicate"
    },

    // Messages
    messages: {
      saveSuccess: "Successfully saved changes",
      saveError: "Error saving changes",
      deleteSuccess: "Successfully deleted",
      deleteError: "Error deleting item",
      invalidPythonDict: "Input must be a valid Python dictionary format (e.g. {'key': 'value'} or {}). Keys and values must be strings with quotes.",
      duplicateQuestionVersion: "يوجد بالفعل سؤال بهذا الاسم والإصدار. يرجى اختيار اسم أو إصدار مختلف.",
      duplicateTestName: "يوجد بالفعل اختبار بهذا الاسم لهذا السؤال. يرجى اختيار اسم اختبار مختلف.",
      duplicateWithTests: "هل تريد نسخ الاختبارات المرتبطة أيضاً؟",
      invalidVersion: "يجب أن يكون الإصدار إما رقمًا أو حروفًا أو رقمًا متبوعًا بحروف (مثل: '1'، 'alpha'، '1beta')",
      invalidExpectedOutput: "يجب أن يكون الناتج المتوقع رقمًا، True، False، None، مجموعة، أو نص محاط بعلامات اقتباس",
    }
  },
  practice: {
    title: 'مدقق كود Python',
    darkmode: 'الوضع الليلي',
    lightmode: 'الوضع الفاتح',
    font: 'حجم الخط',
    run: 'تشغيل',
    submit: 'إرسال',
    output: 'الناتج:',
    questionNotFound: 'السؤال {function_name} غير موجود.',
    didYouMean: 'هل تقصد {match}؟'
  },
  results: {
    functionName: 'اسم الوظيفة',
    timeComplexity: 'تعقيد الوقت',
    test: 'الاختبار',
    error: 'خطأ',
    pass: 'ناجح',
    fail: 'فشل',
    noTest: 'لا يوجد اختبار',
    noLimit: 'بدون حد',
    input: 'Input:',
    expected: 'Expected Output:',
    actual: 'Actual Output:',
    failedRecursion: 'Code should be recursive',
    failedLoops: 'Question allowed for {n_loops_allowed} loops, but {actual_loops} found',
    failedStructure: 'Bad structure',
  },
  performance: {
    title: 'جدول التقديمات المحدثة',
  },
  sign: {
    in: 'تسجيل الدخول',
    up: 'إنشاء حساب',
    email: 'البريد الإلكتروني',
    name: 'الاسم',
    back: 'رجوع',
    password: 'كلمة المرور',
    forgotPassword: 'هل نسيت كلمة المرور؟',
    resetPassword: 'إعادة تعيين كلمة المرور',
    codeWill: 'سيتم إرسال رمز إعادة التعيين إلى بريدك الإلكتروني:',
    sendCode: 'إرسال رمز إعادة التعيين',
    defaultCode: 'أدخل رمز التأكيد',
    defaultPassword: 'أدخل كلمة المرور الجديدة',
    confirmAccount: 'تأكيد حسابك',
    confirmationCode: 'رمز التأكيد',
    confirmAccountNow: 'تأكيد الحساب',
    resendCode: 'إعادة إرسال الرمز',
    changeSuccess: 'تمت إعادة تعيين كلمة المرور بنجاح!',
  }
};
