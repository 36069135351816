import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import I18n from '../i18n';
import '../css/AdminPage.css';
import { LanguageContext } from './LanguageContext';
import DisplayQuestionTable from './tables/DisplayQuestionTable';
import DisplayTestTable from './tables/DisplayTestTable';

const validateAndParseVersion = (value) => {
  // Case 1: Number followed by letter(s) (e.g., "1a", "2beta")
  const numberLetterMatch = value.match(/^(\d+)([a-zA-Z]+)$/);
  if (numberLetterMatch) {
    return {
      isValid: true,
      version_int: parseInt(numberLetterMatch[1]),
      version_str: numberLetterMatch[2]
    };
  }

  // Case 2: Only number
  const numberMatch = value.match(/^\d+$/);
  if (numberMatch) {
    return {
      isValid: true,
      version_int: parseInt(value),
      version_str: ''
    };
  }

  // Case 3: Only letters
  const letterMatch = value.match(/^[a-zA-Z]+$/);
  if (letterMatch) {
    return {
      isValid: true,
      version_int: 0,
      version_str: value
    };
  }

  // Invalid format
  return {
    isValid: false,
    message: I18n.t('admin.messages.invalidVersion')
  };
};

const validateExpectedOutput = (value) => {
  // Skip validation if empty
  if (!value || value.trim() === '') return true;

  // Check if it's a number
  if (!isNaN(value)) return true;

  // Check if it's a Python boolean or None
  if (['True', 'False', 'None'].includes(value)) return true;

  // Check if it starts with {, [, or ( for collections
  if (value.trim().startsWith('{') || 
      value.trim().startsWith('[') || 
      value.trim().startsWith('(')) return true;

  // Check if it's properly quoted (single or double quotes)
  if ((value.startsWith("'") && value.endsWith("'")) || 
      (value.startsWith('"') && value.endsWith('"'))) return true;

  return false;
};

const AdminPage = ({ userInfo }) => {
  const navigate = useNavigate();
  const apiUrl = config.API_URL;
  const { language } = useContext(LanguageContext);

  const validatePythonDict = (str) => {
    if (str.trim() === '{}') return true;
    
    const pythonDictRegex = /^\{(\s*['"][^'"]+['"]\s*:\s*[^,}]*\s*,?\s*)*\}$/;
    return pythonDictRegex.test(str);
  };

  const [questions, setQuestions] = useState([]);
  const [tests, setTests] = useState([]);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editingTestId, setEditingTestId] = useState(null);
  const [editingData, setEditingData] = useState(null);
  const [editingDataMap, setEditingDataMap] = useState({});
  const [duplicatedQuestions, setDuplicatedQuestions] = useState([]);
  const [duplicatedTests, setDuplicatedTests] = useState([]);
  const [newQuestion, setNewQuestion] = useState(null);
  const [newTest, setNewTest] = useState(null);
  const newRowRef = useRef(null);

  const [title, setTitle] = useState(I18n.t('admin.title'));
  const [upload, setUpload] = useState(I18n.t('admin.upload'));
  const [verify, setVerify] = useState(I18n.t('admin.verify'));
  const [alertNoFile, setAlertNoFile] = useState(I18n.t('admin.alertNoFile'));
  const [alertSuccess, setAlertSuccess] = useState(I18n.t('admin.alertSuccess'));
  const [alertPleaseUpload, setAlertPleaseUpload] = useState(I18n.t('admin.alertPleaseUpload'));
  const [defaultText, setDefaultText] = useState(I18n.t('admin.default'));
  const [loading, setLoading] = useState(I18n.t('basic.loading'));

  const [statusMessage, setStatusMessage] = useState(null);
  const [statusType, setStatusType] = useState('success'); // or 'error'

  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  // Add new state to track pending test duplicates
  const [pendingTestDuplicates, setPendingTestDuplicates] = useState(new Map());

  useEffect(() => {
    setTitle(I18n.t('admin.title'));
    setUpload(I18n.t('admin.upload'));
    setVerify(I18n.t('admin.verify'));
    setAlertNoFile(I18n.t('admin.alertNoFile'));
    setAlertSuccess(I18n.t('admin.alertSuccess'));
    setAlertPleaseUpload(I18n.t('admin.alertPleaseUpload'));
    setDefaultText(I18n.t('admin.default'));
    setLoading(I18n.t('basic.loading'));
  }, [language]); // Rerun whenever the language changes
  
  useEffect(() => {
    if (userInfo !== null) {
      const isAdmin = userInfo && userInfo.type === 'admin';
      if (!isAdmin) {
        navigate('/'); 
      }
    }
  }, [userInfo, navigate]);

  // Fetch questions and tests on component mount
  useEffect(() => {
    fetchQuestions();
    fetchTests();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(`${apiUrl}/questions/get_all_questions`);
      setQuestions(response.data.questions);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const fetchTests = async () => {
    try {
      const response = await axios.get(`${apiUrl}/tests/get_all_tests`);
      setTests(response.data.tests);
    } catch (error) {
      console.error('Error fetching tests:', error);
    }
  };

  const handleQuestionEdit = (question) => {
    // Create the initial version string by concatenating version_int and version_str
    const initialVersionStr = `${question.version_int || ''}${question.version_str || ''}`;
    
    const editData = {
      ...question,
      version_str: initialVersionStr  // Store the concatenated version in version_str for editing
    };

    if (question.id.toString().startsWith('dup_')) {
      setEditingDataMap(prev => ({
        ...prev,
        [question.id]: editData
      }));
    } else {
      setEditingData(editData);
    }
    setEditingQuestionId(question.id);
  };

  const handleTestEdit = (test) => {
    setEditingDataMap(prev => ({
      ...prev,
      [test.id]: { ...test }
    }));
    setEditingTestId(test.id);
    setEditingData(test);
  };

  const handleQuestionSave = async (id) => {
    const questionToSave = id 
      ? (id.toString().startsWith('dup_') ? editingDataMap[id] : editingData) 
      : newQuestion;
    
    // Validate version format before saving
    const versionResult = validateAndParseVersion(questionToSave.version_str);
    if (!versionResult.isValid) {
      alert(versionResult.message);
      return;
    }

    // Update the version values based on validation
    questionToSave.version_str = versionResult.version_str;
    questionToSave.version_int = versionResult.version_int;

    // Check for duplicate name + version combination
    const isDuplicate = questions.some(q => 
      q.id !== questionToSave.id && 
      q.name === questionToSave.name && 
      q.version_int === questionToSave.version_int &&
      q.version_str === questionToSave.version_str
    );

    if (isDuplicate) {
      alert(I18n.t('admin.messages.duplicateQuestionVersion'));
      return;
    }

    try {
      // Save the question first
      const savedQuestionResponse = await saveQuestion(questionToSave);
      const newQuestionId = savedQuestionResponse.question_id;  // Make sure this matches your API response
      
      // If this was a duplicated question and it has pending test duplicates
      if (id?.toString().startsWith('dup_') && pendingTestDuplicates.has(id)) {
        console.log('Duplicating tests for question:', id); // Debug log
        const testsToSave = pendingTestDuplicates.get(id);
        
        // Save each test with the new question_id
        for (const test of testsToSave) {
          const testToSave = {
            test_name: test.test_name.replace('_copy', ''),  // Remove _copy suffix
            input: test.input,
            expected: test.expected,
            allow_each_expected: test.allow_each_expected,
            question_id: newQuestionId  // Use the new question's ID
          };
          
          await axios.post(`${apiUrl}/tests/add_test`, testToSave);
        }
        
        // Clear the pending duplicates for this question
        setPendingTestDuplicates(prev => {
          const newMap = new Map(prev);
          newMap.delete(id);
          return newMap;
        });
      }

      // Fetch fresh data
      const questionsResponse = await axios.get(`${apiUrl}/questions/get_all_questions`);
      setQuestions(questionsResponse.data.questions);
      await fetchTests();  // Also refresh tests
      
      // Clear edit states
      setEditingQuestionId(null);
      setEditingData(null);
      setEditingDataMap(prev => {
        const newMap = { ...prev };
        delete newMap[id];
        return newMap;
      });
      setDuplicatedQuestions(prev => prev.filter(qId => qId !== id));
      setNewQuestion(null);
      
      alert(I18n.t('admin.messages.saveSuccess'));
    } catch (error) {
      console.error('Error in handleQuestionSave:', error);
      alert(I18n.t('admin.messages.saveError'));
    }
  };

  const handleTestSave = async (id) => {
    const testToSave = id 
      ? (id.toString().startsWith('dup_') ? editingDataMap[id] : editingData) 
      : newTest;

    try {
      // ... validation code ...

      if (!id || id.toString().startsWith('new_')) {  // Add check for new_ prefix
        // Handle new test
        await axios.post(`${apiUrl}/tests/add_test`, testToSave);
        setNewTest(null);  // Clear new test state
        setEditingData(null);  // Clear editing data
      } else if (id.toString().startsWith('dup_')) {
        // Handle duplicated test
        await axios.post(`${apiUrl}/tests/add_test`, testToSave);
        // Clean up duplicate state
        setEditingDataMap(prev => {
          const newMap = { ...prev };
          delete newMap[id];
          return newMap;
        });
        setDuplicatedTests(prev => prev.filter(testId => testId !== id));
      } else {
        // Handle existing test update
        await axios.put(`${apiUrl}/tests/update/${id}`, testToSave);
        setEditingTestId(null);  // Clear editing state
        setEditingData(null);
      }

      // Fetch fresh data after save
      await fetchTests();
      
      alert(I18n.t('admin.messages.saveSuccess'));
    } catch (error) {
      console.error('Error saving test:', error);
      console.error('Test data being saved:', testToSave);
      alert(I18n.t('admin.messages.saveError'));
    }
  };

  const handleQuestionDelete = async (questionId) => {
    if (window.confirm(I18n.t('admin.confirmDeleteQuestion'))) {
      try {
        await axios.delete(`${apiUrl}/questions/delete_question_id/${questionId}`);
        fetchQuestions();
      } catch (error) {
        console.error('Error deleting question:', error);
      }
    }
  };

  const handleTestDelete = async (testId) => {
    if (window.confirm(I18n.t('admin.confirmDeleteTest'))) {
      try {
        await axios.delete(`${apiUrl}/tests/delete_test_id/${testId}`);
        fetchTests();
      } catch (error) {
        console.error('Error deleting test:', error);
      }
    }
  };

  const handleAddQuestion = () => {
    setNewQuestion({
      id: `new_${Date.now()}`,  // Add a temporary ID
      name: '',
      text: '',
      version_int: 1,
      version_str: '',
      difficulty: 1,
      time_complexity: 'O(n)',
      is_recursive: 0,
      max_loop_count: 1
    });
    setTimeout(() => {
      newRowRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);
  };

  const handleAddTest = (questionId) => {
    const newTestData = {
      id: `new_${Date.now()}`,  // Add temporary ID
      question_id: questionId,
      test_name: 'test_',
      input: '',
      expected: '',
      allow_each_expected: 0
    };
    setNewTest(newTestData);
    setEditingData(newTestData);  // Set editing data to enable edit mode
    setTimeout(() => {
      newRowRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);
  };

  const handleQuestionRowClick = (questionId) => {
    console.log('Clicked question ID:', questionId);
    console.log('Current selectedQuestionId:', selectedQuestionId);
    setSelectedQuestionId(questionId === selectedQuestionId ? null : questionId);
  };

  const handleQuestionDuplicate = async (question, e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Check if question has associated tests
    const associatedTests = tests.filter(test => test.question_id === question.id);
    
    // Create the duplicate question
    const timestamp = Date.now();
    const newQuestionId = `dup_${question.id}_${timestamp}`;
    const newDuplicate = {
      ...question,
      id: newQuestionId,
      name: `${question.name}_copy`
    };

    // If there are associated tests, ask user if they want to duplicate them
    let duplicateTests = false;
    if (associatedTests.length > 0) {
      duplicateTests = window.confirm(I18n.t('admin.messages.duplicateWithTests'));
    }

    // Store the duplicated tests in memory if user wants them
    if (duplicateTests) {
      const duplicatedTests = associatedTests.map(test => ({
        ...test,
        id: `dup_${test.id}_${timestamp}`,
        question_id: newQuestionId,
        test_name: `${test.test_name}_copy`
      }));
      
      setPendingTestDuplicates(prev => new Map([
        ...prev,
        [newQuestionId, duplicatedTests]
      ]));
    }

    // Add the duplicated question to edit mode
    setEditingDataMap(prev => ({
      ...prev,
      [newQuestionId]: newDuplicate
    }));
    setDuplicatedQuestions(prev => [...prev, newQuestionId]);
  };

  const handleTestDuplicate = (test, e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const uniqueId = `dup_${test.id}_${Date.now()}`;
    const newDuplicate = {
      ...test,
      id: uniqueId,
      test_name: `${test.test_name}_copy`
    };
    
    React.startTransition(() => {
      setEditingDataMap(prev => ({
        ...prev,
        [uniqueId]: newDuplicate
      }));
      setDuplicatedTests(prev => [...prev, uniqueId]);
      // Start in edit mode immediately
      setEditingData(newDuplicate);
      setEditingTestId(uniqueId);
    });
  };

  const handleQuestionCancel = (id) => {
    // If this was a duplicated question with pending tests, clean them up
    if (id?.toString().startsWith('dup_')) {
      setPendingTestDuplicates(prev => {
        const newMap = new Map(prev);
        newMap.delete(id);
        return newMap;
      });
    }
    
    setEditingQuestionId(null);
    setEditingData(null);
    setDuplicatedQuestions(prev => prev.filter(qId => qId !== id));
  };

  const handleTestCancel = (testId) => {
    if (testId.toString().startsWith('dup_')) {
      setEditingDataMap(prev => {
        const newMap = {...prev};
        delete newMap[testId];
        return newMap;
      });
      setDuplicatedTests(prev => prev.filter(id => id !== testId));
    } else {
      setEditingTestId(null);
      setEditingData(null);
    }
  };

  // Add these functions near the top with other API calls
  const saveQuestion = async (question) => {
    try {
      if (question.id === undefined || question.id === null || question.id.toString().startsWith('dup_') || question.id.toString().startsWith('new_')) {
        // For new questions and duplicates
        const questionToSave = {
          ...question,
          id: undefined
        };
        const response = await axios.post(`${apiUrl}/questions/add_question`, questionToSave);
        return response.data;
      } else {
        // For updating existing questions - fixed endpoint
        const response = await axios.put(`${apiUrl}/questions/update/${question.id}`, question);
        return response.data;
      }
    } catch (error) {
      console.error('Error saving question:', error);
      console.error('Question data being saved:', question);
      throw error;
    }
  };

  if (userInfo === null) {
    // Optionally render a loading indicator while userInfo is being fetched
    return <div>{loading}</div>;
  }

  return (
    <div className="admin-page">
      <h2>{title}</h2>
      
      <section className="questions-section">
        <div className="section-header">
          <h3>{I18n.t('admin.questions')}</h3>
          <button onClick={handleAddQuestion}>{I18n.t('admin.addQuestion')}</button>
        </div>
        <DisplayQuestionTable
          questions={questions}
          editingQuestionId={editingQuestionId}
          editingData={editingData}
          editingDataMap={editingDataMap}
          setEditingDataMap={setEditingDataMap}
          duplicatedRows={duplicatedQuestions}
          newQuestion={newQuestion}
          newRowRef={newRowRef}
          selectedQuestionId={selectedQuestionId}
          handleQuestionEdit={handleQuestionEdit}
          handleQuestionDelete={handleQuestionDelete}
          handleQuestionSave={handleQuestionSave}
          handleQuestionDuplicate={handleQuestionDuplicate}
          handleQuestionCancel={handleQuestionCancel}
          setEditingQuestionId={setEditingQuestionId}
          setEditingData={setEditingData}
          setNewQuestion={setNewQuestion}
          handleQuestionRowClick={handleQuestionRowClick}
        />
      </section>

      {selectedQuestionId && (
        <section className="tests-section">
          <div className="section-header">
            <h3>{I18n.t('admin.tests')} for {questions.find(q => q.id === selectedQuestionId)?.name}</h3>
            <button onClick={() => handleAddTest(selectedQuestionId)}>{I18n.t('admin.addTest')}</button>
          </div>
          <DisplayTestTable
            tests={tests.filter(test => test.question_id === selectedQuestionId)}
            questions={questions}
            editingTestId={editingTestId}
            editingData={editingData}
            editingDataMap={editingDataMap}
            setEditingDataMap={setEditingDataMap}
            duplicatedRows={duplicatedTests}
            newTest={newTest}
            newRowRef={newRowRef}
            selectedQuestionId={selectedQuestionId}
            handleTestEdit={handleTestEdit}
            handleTestDelete={handleTestDelete}
            handleTestSave={handleTestSave}
            handleTestDuplicate={handleTestDuplicate}
            handleTestCancel={handleTestCancel}
            setEditingTestId={setEditingTestId}
            setEditingData={setEditingData}
            setNewTest={setNewTest}
          />
        </section>
      )}
    </div>
  );
};

export default AdminPage;