export default {
  basic: {
    loading: 'Loading...',
    selectLanguage: 'Select Language',
  },
  navbar: {
    home: 'Home',
    members: 'Members Area',
    account: 'Account',
    practice: 'Practice System',
    performance: 'Performance',
    signin: 'Sign In',
    signup: 'Sign Up',
    signout: 'Sign Out',
    about: 'About Us',
    contact: 'Contact',
    admin: 'Admin',
  },
  home: {
    introTitle: 'Hello and welcome to testapp!',
    introSubTitle: 'A unique practice system that allows you to prepare for the exam with',
    introSubTitle2: 'winning success!',
    intro1: 'Hello',
    intro2: 'My name is Elior Shaked',
    intro3: 'I invite you to practice your coding skills in a',
    intro4: 'creative and independent way',
    intro5: 'Our system provides feedback on various tests for common exam questions, allowing you to assess the quality of your code while meeting the question requirements (recursion, runtime, etc...). Our journey is long, but we will make it together with a clear goal - to help you code optimally',
    intro6: 'and succeed in the exam',
    videoError: 'Error loading the video',
  },
  account: {
    title: 'Account Page',
    name: 'Name:',
    email: 'Email:',
  },
  contact: {
    questions: 'For any questions:',
    email: 'Email:',
  },
  about: {
    founder1: 'Elior: A good boy',
    founder2: 'Amit: A bad boy',
  },
  admin: {
    title: 'Admin Page - SECRET PAGE!',
    upload: 'Upload File',
    default: 'No file selected',
    verify: 'Verify and Upload CSV',
    alertNoFile: 'No file selected',
    alertSuccess: 'File processed successfully',
    alertPleaseUpload: 'Please upload a file with a .csv extension',
    questions: "Questions",
    tests: "Tests",
    addQuestion: "Add New Question",
    addTest: "Add New Test", 
    confirmDeleteQuestion: "Are you sure you want to delete this question? This will also delete all associated tests.",
    confirmDeleteTest: "Are you sure you want to delete this test?",
    
    // Table headers
    tableHeaders: {
      name: "Name",
      text: "Question Text",
      version: "Version",
      difficulty: "Difficulty",
      timeComplexity: "Time Complexity",
      recursive: "Recursive",
      maxLoops: "Max Loops",
      actions: "Actions",
      questionName: "Question Name",
      testName: "Test Name",
      input: "Input",
      expected: "Expected Output",
      allowEachExpected: "Allow Each Expected",
      testId: "ID",
      questionId: "ID",
      tooltips: {
        questionId: "Automatically generated ID",
        name: "Question name - should be unique and descriptive\nExample: \"Binary Tree Traversal\"",
        text: "Question description and requirements\nExample: \"Implement a function that traverses a binary tree in-order\"",
        version: "Version format: number, letters, or number followed by letters\nExamples: \"1\", \"alpha\", \"1beta\", \"2a\"",
        difficulty: "Difficulty level - any number",
        timeComplexity: "Expected time complexity of the solution\nOptions: '', O(1), O(logn), O(n), O(nlogn), O(n^2), O(n^3), O(n^4), O(2^n)",
        recursive: "Indicates if the solution requires recursion\nYes/No value",
        maxLoops: "Maximum number of loops allowed in the solution\n-1 means no limit\nExample: 2 means maximum two nested loops",
        actions: "Available actions: Edit, Duplicate, Delete",
        testId: "Automatically generated ID",
        questionName: "The question this test belongs to\nSelect from existing questions",
        testName: "Descriptive name for the test case\nExample: \"Empty Array Test\", \"Edge Case - Negative Numbers\"",
        input: "Input value in Python dictionary format\nExamples:\n{\"array\": [1, 2, 3]}\n{\"str\": \"hello\"}\n{} for empty input",
        expected: "Expected output value\nValid formats:\n- Numbers: 42, -1.5\n- Strings: \"hello\"\n- Booleans: True, False\n- None\n- Collections: [1, 2, 3], {\"key\": \"value\"}, (1, 2)",
        allowEachExpected: "If enabled, expected output should be a list, and each of the values will be accepted"
      }
    },

    // Button labels
    buttons: {
      edit: "Edit",
      delete: "Delete",
      save: "Save",
      cancel: "Cancel",
      duplicate: "Duplicate"
    },

    // Messages
    messages: {
      saveSuccess: "Successfully saved changes",
      saveError: "Error saving changes",
      deleteSuccess: "Successfully deleted",
      deleteError: "Error deleting item",
      invalidPythonDict: "Input must be a valid Python dictionary format (e.g. {'key': 'value'} or {}). Keys and values must be strings with quotes.",
      duplicateQuestionVersion: "A question with this name and version already exists. Please choose a different name or version.",
      duplicateTestName: "A test with this name already exists for this question. Please choose a different test name.",
      duplicateWithTests: "Would you like to duplicate the associated tests as well?",
      invalidVersion: "Version must be either a number, letters, or a number followed by letters (e.g., '1', 'alpha', '1beta')",
      invalidExpectedOutput: "Expected output must be a number, True, False, None, a collection, or a quoted string",
    }
  },
  practice: {
    title: 'Python Code Checker',
    darkmode: 'Dark Mode',
    lightmode: 'Light Mode',
    font: 'Font Size',
    run: 'Run',
    submit: 'Submit',
    output: 'Output:',
    questionNotFound: 'Question {function_name} was not found.',
    didYouMean: 'Did you mean {match}?',
  },
  results: {
    functionName: 'Function Name',
    timeComplexity: 'Time Complexity',
    test: 'Test',
    error: 'Error',
    pass: 'Passed',
    fail: 'Failed',
    noTest: 'No Test',
    noLimit: 'No limit',
    input: 'Input:',
    expected: 'Expected Output:',
    actual: 'Actual Output:',
    failedRecursion: 'Code should be recursive',
    failedLoops: 'Question allowed {n_loops_allowed} loops, but {actual_loops} found',
    failedStructure: 'Bad structure',
  }, 
  performance: {
    title: ' Updated Submissions Table',
  },
  sign: {
    in: 'Sign In',
    up: 'Sign Up',
    email: 'Email',
    name: 'Name',
    back: 'Back',
    password: 'Password',
    forgotPassword: 'Forgot Password?',
    resetPassword: 'Reset Password',
    codeWill: 'A reset code will be sent to your email:',
    sendCode: 'Send Reset Code',
    defaultCode: 'Enter confirmation code',
    defaultPassword: 'Enter new password',
    confirmAccount: 'Confirm Your Account',
    confirmationCode: 'Confirmation Code',
    confirmAccountNow: 'Confirm Account',
    resendCode: 'Resend Code',
    changeSuccess: 'Password has been reset successfully!',
  } 
  };