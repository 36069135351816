import React from 'react';
import TableActions from './TableActions';
import I18n from '../../i18n';

const QuestionRow = ({
  question,
  isEditing,
  editData,
  onEdit,
  onDuplicate,
  onDelete,
  onSave,
  onCancel,
  onChange
}) => {
  const timeComplexityOptions = [
    '', 'O(1)', 'O(logn)', 'O(n)', 'O(nlogn)', 'O(n^2)', 
    'O(n^3)', 'O(n^4)', 'O(2^n)'
  ];

  if (isEditing) {
    return (
      <>
        <TableActions
          item={question}
          isEditing={true}
          onSave={onSave}
          onCancel={onCancel}
        />
        <td>{question.id.toString().startsWith('new_') ? 'New' : question.id}</td>
        <td>
          <input 
            value={editData?.name || ''} 
            onChange={e => onChange({...editData, name: e.target.value})}
          />
        </td>
        <td>
          <textarea 
            value={editData?.text || ''} 
            onChange={e => onChange({...editData, text: e.target.value})}
          />
        </td>
        <td>
          <input 
            value={editData?.version_str || ''} 
            onChange={e => onChange({...editData, version_str: e.target.value})}
          />
        </td>
        <td>
          <input 
            type="number" 
            value={editData?.difficulty || 0} 
            onChange={e => onChange({...editData, difficulty: parseInt(e.target.value)})}
          />
        </td>
        <td>
          <select 
            value={editData?.time_complexity || ''} 
            onChange={e => onChange({...editData, time_complexity: e.target.value})}
          >
            <option value="">Select complexity</option>
            {timeComplexityOptions.filter(option => option !== '').map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </td>
        <td>
          <input 
            type="checkbox" 
            checked={editData?.is_recursive === 1} 
            onChange={e => onChange({...editData, is_recursive: e.target.checked ? 1 : 0})}
          />
        </td>
        <td>
          <input 
            type="number" 
            value={editData?.max_loop_count || 0} 
            onChange={e => onChange({...editData, max_loop_count: parseInt(e.target.value)})}
          />
        </td>
      </>
    );
  }

  return (
    <>
      <TableActions
        item={question}
        onEdit={onEdit}
        onDuplicate={onDuplicate}
        onDelete={onDelete}
      />
      <td>{question.id.toString().startsWith('new_') ? 'New' : question.id}</td>
      <td>{question.name}</td>
      <td>{question.text}</td>
      <td>{question.version_int}{question.version_str}</td>
      <td>{question.difficulty}</td>
      <td>{question.time_complexity}</td>
      <td>{question.is_recursive ? 'Yes' : 'No'}</td>
      <td>{question.max_loop_count}</td>
    </>
  );
};

export default QuestionRow; 