import React from 'react';
import I18n from '../../i18n';

const DisplayTable = ({ 
  headers, 
  data, 
  editingId,
  editingDataMap,
  duplicatedRows,
  newItem, 
  newRowRef,
  renderEditRow,
  renderDisplayRow,
  renderNewRow
}) => {
  const rowMap = new Map();
  
  data.forEach(item => {
    rowMap.set(item.id.toString(), item);
  });
  
  duplicatedRows.forEach(dupId => {
    const dupItem = editingDataMap[dupId];
    if (dupItem) {
      const originalId = dupId.replace('dup_', '').split('_')[0];
      const index = Array.from(rowMap.keys()).findIndex(key => key === originalId.toString());
      
      const entries = Array.from(rowMap.entries());
      entries.splice(index + 1, 0, [dupId, dupItem]);
      rowMap.clear();
      entries.forEach(([key, value]) => rowMap.set(key, value));
    }
  });

  const allRows = Array.from(rowMap.values());

  return (
    <div className="table-container">
      <table className="admin-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th 
                key={index} 
                className={header.className}
                title={header.tooltip}
              >
                {header.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allRows.map(item => (
            <tr 
              key={item.id}
              className={item.id.toString().startsWith('dup_') ? 'duplicate-row' : item.className}
              onClick={!item.id.toString().startsWith('dup_') ? item.onClick : undefined}
              data-duplicate={item.id.toString().startsWith('dup_')}
            >
              {(editingId === item.id || duplicatedRows.includes(item.id)) ? (
                renderEditRow({
                  ...item,
                  displayId: item.id.toString().startsWith('dup_') ? 'New' : item.id
                })
              ) : (
                renderDisplayRow(item)
              )}
            </tr>
          ))}
          {newItem && (
            <tr ref={newRowRef}>
              {renderNewRow()}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DisplayTable; 