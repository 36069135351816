import React from 'react';
import I18n from '../../i18n';

const TableActions = ({ 
  item,
  onEdit,
  onDuplicate,
  onDelete,
  isEditing = false,
  onSave,
  onCancel
}) => {
  const handleClick = (e, action) => {
    e.stopPropagation();
    action();
  };

  if (isEditing) {
    return (
      <td className="table-actions">
        <button className="save" onClick={(e) => handleClick(e, () => onSave(item.id))}>
          {I18n.t('admin.buttons.save')}
        </button>
        <button className="cancel" onClick={(e) => handleClick(e, () => onCancel(item.id))}>
          {I18n.t('admin.buttons.cancel')}
        </button>
      </td>
    );
  }

  return (
    <td className="table-actions">
      <button 
        className="edit" 
        onClick={(e) => handleClick(e, () => onEdit(item))}
      >
        {I18n.t('admin.buttons.edit')}
      </button>
      <button 
        className="duplicate" 
        onClick={(e) => {
          e.stopPropagation();
          onDuplicate(item, e);
        }}
      >
        {I18n.t('admin.buttons.duplicate')}
      </button>
      <button 
        className="delete" 
        onClick={(e) => handleClick(e, () => onDelete(item.id))}
      >
        {I18n.t('admin.buttons.delete')}
      </button>
    </td>
  );
};

export default TableActions; 