import React from 'react';
import I18n from '../../i18n';
import { TEST_COLUMNS } from './config/tableConfigs';
import TestRow from './TestRow';
import DisplayTable from './DisplayTable';

const DisplayTestTable = ({
  tests,
  questions,
  editingTestId,
  editingData,
  editingDataMap,
  setEditingDataMap,
  duplicatedRows,
  newTest,
  newRowRef,
  selectedQuestionId,
  handleTestEdit,
  handleTestDelete,
  handleTestSave,
  handleTestDuplicate,
  handleTestCancel,
  setEditingTestId,
  setEditingData,
  setNewTest,
}) => {
  const headers = [  // Define headers here instead of using COLUMNS
    { 
      title: I18n.t('admin.tableHeaders.actions'), 
      className: 'col-actions',
      tooltip: I18n.t('admin.tableHeaders.tooltips.actions')
    },
    { 
      title: I18n.t('admin.tableHeaders.testId'), 
      className: 'col-id',
      tooltip: I18n.t('admin.tableHeaders.tooltips.testId')
    },
    { 
      title: I18n.t('admin.tableHeaders.questionName'), 
      className: 'col-question-name',
      tooltip: I18n.t('admin.tableHeaders.tooltips.questionName')
    },
    { 
      title: I18n.t('admin.tableHeaders.testName'), 
      className: 'col-test-name',
      tooltip: I18n.t('admin.tableHeaders.tooltips.testName')
    },
    { 
      title: I18n.t('admin.tableHeaders.input'), 
      className: 'col-input',
      tooltip: I18n.t('admin.tableHeaders.tooltips.input')
    },
    { 
      title: I18n.t('admin.tableHeaders.expected'), 
      className: 'col-expected',
      tooltip: I18n.t('admin.tableHeaders.tooltips.expected')
    },
    { 
      title: I18n.t('admin.tableHeaders.allowEachExpected'), 
      className: 'col-checkbox',
      tooltip: I18n.t('admin.tableHeaders.tooltips.allowEachExpected')
    }
  ];

  const renderRow = (test) => {
    if (!test) return null;
    
    const isDuplicate = test.id?.toString().startsWith('dup_');
    const isNew = test.id?.toString().startsWith('new_');
    const isEditing = editingTestId === test.id || duplicatedRows.includes(test.id) || isNew;
    const currentEditData = isDuplicate ? editingDataMap[test.id] : editingData;

    return (
      <TestRow
        test={test}
        questions={questions}
        isEditing={isEditing}
        editData={isNew ? test : currentEditData}
        onEdit={handleTestEdit}
        onDuplicate={handleTestDuplicate}
        onDelete={handleTestDelete}
        onSave={handleTestSave}
        onCancel={handleTestCancel}
        onChange={isDuplicate 
          ? (data) => setEditingDataMap(prev => ({...prev, [test.id]: data}))
          : setEditingData
        }
      />
    );
  };

  const relevantTests = [
    ...tests,
    ...duplicatedRows
      .map(id => editingDataMap[id])
      .filter(test => test && test.question_id === selectedQuestionId)
  ];

  const renderNewRow = () => {
    if (!newTest) return null;
    
    return (
      <TestRow
        test={{ ...newTest, displayId: 'New' }}  // Add displayId for new tests
        questions={questions}
        isEditing={true}
        editData={newTest}
        onEdit={handleTestEdit}
        onDuplicate={handleTestDuplicate}
        onDelete={handleTestDelete}
        onSave={handleTestSave}
        onCancel={() => {
          setNewTest(null);
          setEditingData(null);
        }}
        onChange={(data) => {
          setNewTest(data);
          setEditingData(data);
        }}
      />
    );
  };

  return (
    <DisplayTable
      headers={headers}
      data={relevantTests}
      editingId={editingTestId}
      editingDataMap={editingDataMap}
      duplicatedRows={duplicatedRows}
      newItem={newTest}
      newRowRef={newRowRef}
      renderEditRow={renderRow}
      renderDisplayRow={renderRow}
      renderNewRow={renderNewRow}  // Use the new renderNewRow function directly
    />
  );
};

export default DisplayTestTable; 