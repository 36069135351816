import React from 'react';
import TableActions from './TableActions';
import I18n from '../../i18n';

const TestRow = ({
  test,
  questions = [],
  isEditing = false,
  editData,
  onEdit,
  onDuplicate,
  onDelete,
  onSave,
  onCancel,
  onChange
}) => {
  if (isEditing) {
    return (
      <>
        <TableActions
          item={test}
          isEditing={true}
          onSave={onSave}
          onCancel={onCancel}
        />
        <td>{test.displayId || test.id}</td>
        <td>
          <select 
            value={editData?.question_id || ''}
            onChange={e => onChange({ ...editData, question_id: parseInt(e.target.value) })}
          >
            <option value="">Select a question</option>
            {questions.map(q => (
              <option key={q.id} value={q.id}>
                {q.name || 'Unnamed Question'}
              </option>
            ))}
          </select>
        </td>
        <td>
          <input 
            value={editData?.test_name || ''} 
            onChange={e => onChange({ ...editData, test_name: e.target.value })} 
          />
        </td>
        <td>
          <textarea 
            value={editData?.input || ''} 
            onChange={e => onChange({ ...editData, input: e.target.value })} 
          />
        </td>
        <td>
          <textarea 
            value={editData?.expected || ''} 
            onChange={e => onChange({ ...editData, expected: e.target.value })} 
          />
        </td>
        <td>
          <input 
            type="checkbox" 
            checked={editData?.allow_each_expected === 1} 
            onChange={e => onChange({ ...editData, allow_each_expected: e.target.checked ? 1 : 0 })} 
          />
        </td>
      </>
    );
  }

  return (
    <>
      <td>
        <button className="edit" onClick={(e) => {
          e.stopPropagation();
          onEdit(test);
        }}>
          {I18n.t('admin.buttons.edit')}
        </button>
        <button className="duplicate" onClick={(e) => {
          e.stopPropagation();
          onDuplicate(test, e);
        }}>
          {I18n.t('admin.buttons.duplicate')}
        </button>
        <button className="delete" onClick={(e) => {
          e.stopPropagation();
          onDelete(test.id);
        }}>
          {I18n.t('admin.buttons.delete')}
        </button>
      </td>
      <td>{test.id}</td>
      <td>{questions.find(q => q.id === test.question_id)?.name || ''}</td>
      <td>{test.test_name}</td>
      <td>{test.input}</td>
      <td>{test.expected}</td>
      <td>{test.allow_each_expected ? 'Yes' : 'No'}</td>
    </>
  );
};

export default TestRow; 